import React, { useEffect, useState } from "react";
import "./EstadoGeneral.css";
import {
  selectEstadoTablasMirror,
  setLoadingPageScreen,
} from "../../features/Dashboard/Slices/dashboardSlice";
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { dashboardAPI } from "../../Api/dashboardAPI";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import Button from "../../components/reusable/Button/Button";
import { toastError, toastSuccess } from "../../utils/toast";
import {
  getEstadoTablasMirror,
  postActualizarCupos,
} from "../../features/Dashboard/AsyncThunks/estadoGeneral/getEstadoTablasMirror";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";
import SlProgressRing from "@shoelace-style/shoelace/dist/react/progress-ring";

const btnsDescriptions = [
  "1. INFO: Sincroniza la informacion de todas las tablas.",
  "2. INFO: Sincroniza la informacion de todas las tablas.",
  "3. INFO: Sincroniza la informacion de todas las tablas.",
  "4. INFO: Sincroniza la informacion de todas las tablas.",
  "5. INFO: Sincroniza la informacion de todas las tablas.",
  "6. INFO: Sincroniza la informacion de todas las tablas.",
  "7. INFO: Sincroniza la informacion de todas las tablas.",
];

function EstadoGeneral() {
  const dispatch = useAppDispatch();
  const estadoTablasMirror = useSelector(selectEstadoTablasMirror);
  const confirmationModalInitialState = {
    show: false,
    title: "",
    dialog: "",
    onConfirm: () => {},
  };
  const [confirmationModal, setConfirmationModal] = useState(
    confirmationModalInitialState
  );
  const [loadingTable, setLoadingTable] = useState("");
  const [descriptionBtn, setDescriptionBtn] = useState<number | null>(null);

  const countTablesInicializada = estadoTablasMirror.reduce(
    (acc: number, current: any) => {
      return current.estado === "INICIALIZADA" ? acc + 1 : acc;
    },
    0
  );
  const countTablesDesactivada = estadoTablasMirror.reduce(
    (acc: number, current: any) => {
      return current.estado === "DESACTIVADA" ? acc + 1 : acc;
    },
    0
  );
  const porcentajeInicializadas = Math.round(
    (countTablesInicializada * 100) / estadoTablasMirror.length
  );

  const clearConfirmationModal = () => {
    setConfirmationModal(confirmationModalInitialState);
  };

  const setLoading = (newState: boolean) => {
    dispatch(setLoadingPageScreen(newState));
  };

  useEffect(() => {
    actualizarTablasMirror();
  }, []);

  const inicializarConfiguracion = async () => {
    setLoading(true);
    try {
      await dashboardAPI.inicializarConfiguracion();
    } catch (error) {
      toastError();
    }
    setLoading(false);
    clearConfirmationModal();
  };

  const syncInfoTablas = async () => {
    setLoading(true);
    try {
      await dashboardAPI.syncInfoTablas();
      toastSuccess("Diccionario de tablas sincronizado con éxito");
    } catch (error) {
      toastError();
    }
    setLoading(false);
    clearConfirmationModal();
  };

  const sincronizarSiguienteTabla = async () => {
    setLoading(true);
    try {
      await dashboardAPI.sincronizarSiguienteTabla();
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const borrarTodasLasTablas = async () => {
    setLoading(true);
    try {
      await dashboardAPI.deleteAllTables();
    } catch (error) {
      toastError();
    }
    setLoading(false);
    clearConfirmationModal();
  };
  const dropTables = async () => {
    setLoading(true);
    try {
      await dashboardAPI.dropAllTables();
    } catch (error) {
      toastError();
    }
    setLoading(false);
    clearConfirmationModal();
  };
  const actualizarTablasMirror = async () => {
    await dispatch(getEstadoTablasMirror());
  };
  const actualizarCupos = async () => {
    await dispatch(postActualizarCupos());
  };

  useEffect(() => {
    console.log("estadoTablasMirror :>> ", estadoTablasMirror);
  }, [estadoTablasMirror]);

  const populateTable = async (endpoint: string) => {
    try {
      setLoading(true);
      setLoadingTable(endpoint);
      const result = await dashboardAPI.inicializarTablaMirror(endpoint);
      console.log("populateTable result :>> ", result);
      toastSuccess(`Se inicializó correctamente la tabla ${endpoint}`);
      actualizarTablasMirror();
    } catch (error) {
      toastError("Ha ocurrido un error inesperado...");
      console.log("error :>> ", error);
    } finally {
      setLoading(false);
      setLoadingTable("");
    }
  };
  const handleDesactivada = (clave: string) => {
    populateTable(clave);
    clearConfirmationModal();
  };

  const handleShowDetailInfo = (descIndex: number) => {
    if (descIndex === descriptionBtn) {
      setDescriptionBtn(null);
    } else {
      setDescriptionBtn(descIndex);
    }
  };

  const renderCustomTable = () => {
    if (!estadoTablasMirror[0]) {
      return <></>;
    }
    const titles = Object.keys(estadoTablasMirror[0]);
    const populateKeyName = "populateEndpoint";
    return (
      <div className="w-full">
        <div className="grid grid-cols-8 py-1">
          {titles.map((title) => {
            return (
              <p
                className={`text-center font-bold ${
                  title === "clave" ? "col-span-2" : ""
                }`}
              >
                {title === populateKeyName ? "" : title}
              </p>
            );
          })}
        </div>

        {estadoTablasMirror?.map((item: any) => {
          const rows = titles.map((keyOfObject) => {
            const desactivada = item["estado"] === "DESACTIVADA";
            const noInicializada = item["estado"] !== "INICIALIZADA";
            if (keyOfObject === populateKeyName) {
              return (
                <button
                  disabled={desactivada || loadingTable === item.clave}
                  className={`flex items-center gap-2 justify-center bg-accent text-white rounded-full disabled:cursor-not-allowed ${
                    noInicializada ? "opacity-50" : ""
                  }`}
                  onClick={() =>
                    noInicializada
                      ? setConfirmationModal({
                          show: true,
                          onConfirm: () => handleDesactivada(item.clave),
                          title: "¿Seguro desea sincronizar?",
                          dialog: "",
                        })
                      : populateTable(item.clave)
                  }
                >
                  <SlIcon
                    name="arrow-repeat"
                    className={`${
                      loadingTable === item.clave ? "animate-spin" : ""
                    }`}
                  ></SlIcon>
                  Sincronizar
                </button>
              );
            } else {
              return (
                <p
                  className={`${
                    keyOfObject === "clave" ? "col-span-2" : "text-center"
                  } ${desactivada ? "text-red" : ""}`}
                >
                  {item[keyOfObject] || ""}

                  {desactivada && keyOfObject === "clave" && (
                    <span className="w-2 h-2 bg-red rounded-full inline-block ml-2"></span>
                  )}
                </p>
              );
            }
          });
          return (
            <div className="grid grid-cols-8 py-1 border-b-[1px] border-cardBorder">
              {rows}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="p-6 grid grid-cols-4 gap-6">
      <ModalConfirmation
        show={confirmationModal.show}
        onClose={() => setConfirmationModal(confirmationModalInitialState)}
        onConfirm={confirmationModal.onConfirm}
        title={confirmationModal.title}
        message={confirmationModal.dialog}
      />

      <div className="bg-white p-6 rounded-lg col-span-3 border-2 border-cardBorder">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Estado general de tablas
        </h4>
        {renderCustomTable()}
      </div>

      <div className="space-y-6">
        <div className="bg-white p-6 rounded-lg border-2 border-cardBorder">
          <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
            Tablas inicializadas
          </h4>
          <div className="grid grid-cols-2 gap-6">
            <SlProgressRing
              className=""
              value={porcentajeInicializadas}
              style={{
                //@ts-ignore
                "--track-width": "11px",
                "--indicator-width": "12px",
                "--track-color": "#ECEEF2",
                "--indicator-color": "#00C4B3",
              }}
            >
              {porcentajeInicializadas}%
            </SlProgressRing>

            <div className="">
              <p className="font-semibold text-lg">
                {countTablesInicializada}{" "}
                <span className="text-textSecondary">
                  / {estadoTablasMirror.length}
                </span>
              </p>
              <p className="">{countTablesDesactivada} Tablas Desactivadas</p>
            </div>
          </div>
        </div>
        {/* /* -------------------------------------------------------------------------- */
        /*                                   BOTONES                                  */
        /* -------------------------------------------------------------------------- */}
        <div className="bg-white p-6 rounded-lg border-2 border-cardBorder">
          <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
            Acciones globales
          </h4>
          <div className="space-y-2">
            <div className="">
              <div className="flex items-center justify-between">
                <button
                  className="text-textPrimary font-bold flex items-center gap-2 hover:underline  border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
                  onClick={syncInfoTablas}
                >
                  Sync info tablas
                </button>
                <SlIcon
                  className="hover:cursor-pointer"
                  name="info-circle"
                  onClick={() => handleShowDetailInfo(0)}
                ></SlIcon>
              </div>
              {descriptionBtn === 0 && (
                <p className="pt-1 text-textSecondary">{btnsDescriptions[0]}</p>
              )}
            </div>

            <hr className="text-cardBorder" />

            <div className="">
              <div className="flex items-center justify-between">
                <button
                  className="text-textPrimary font-bold flex items-center gap-2 hover:underline  border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
                  onClick={() => {
                    setConfirmationModal({
                      show: true,
                      onConfirm: inicializarConfiguracion,
                      title: "Desea resetear la configuración?",
                      dialog: "",
                    });
                  }}
                >
                  Reset Configuración
                </button>
                <SlIcon
                  className="hover:cursor-pointer"
                  name="info-circle"
                  onClick={() => handleShowDetailInfo(1)}
                ></SlIcon>
              </div>
              {descriptionBtn === 1 && (
                <p className="pt-1 text-textSecondary">{btnsDescriptions[1]}</p>
              )}
            </div>

            <hr className="text-cardBorder" />

            <div className="">
              <div className="flex items-center justify-between">
                <button
                  className="text-textPrimary font-bold flex items-center gap-2 hover:underline  border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
                  onClick={sincronizarSiguienteTabla}
                >
                  Sincronizar Siguiente Tabla
                </button>
                <SlIcon
                  className="hover:cursor-pointer"
                  name="info-circle"
                  onClick={() => handleShowDetailInfo(2)}
                ></SlIcon>
              </div>
              {descriptionBtn === 2 && (
                <p className="pt-1 text-textSecondary">{btnsDescriptions[2]}</p>
              )}
            </div>

            <hr className="text-cardBorder" />

            <div className="">
              <div className="flex items-center justify-between">
                <button
                  className="text-textPrimary font-bold flex items-center gap-2 hover:underline  border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
                  onClick={() => {
                    setConfirmationModal({
                      show: true,
                      onConfirm: borrarTodasLasTablas,
                      title: "¿Desea borrar toda la información?",
                      dialog: "Se perderán todos los registros",
                    });
                  }}
                >
                  Borrar toda la información
                </button>
                <SlIcon
                  className="hover:cursor-pointer"
                  name="info-circle"
                  onClick={() => handleShowDetailInfo(3)}
                ></SlIcon>
              </div>
              {descriptionBtn === 3 && (
                <p className="pt-1 text-textSecondary">{btnsDescriptions[3]}</p>
              )}
            </div>

            <hr className="text-cardBorder" />

            <div className="">
              <div className="flex items-center justify-between">
                <button
                  className="text-textPrimary font-bold flex items-center gap-2 hover:underline  border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
                  onClick={() => {
                    setConfirmationModal({
                      show: true,
                      onConfirm: dropTables,
                      title: "¿Desea borrar todas las tablas?",
                      dialog: "Se perderán todos los registros",
                    });
                  }}
                >
                  Elimina (DROP) todas las tablas
                </button>
                <SlIcon
                  className="hover:cursor-pointer"
                  name="info-circle"
                  onClick={() => handleShowDetailInfo(4)}
                ></SlIcon>
              </div>
              {descriptionBtn === 4 && (
                <p className="pt-1 text-textSecondary">{btnsDescriptions[4]}</p>
              )}
            </div>

            <hr className="text-cardBorder" />

            <div className="">
              <div className="flex items-center justify-between">
                <button
                  className="text-textPrimary font-bold flex items-center gap-2 hover:underline  border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
                  onClick={actualizarTablasMirror}
                >
                  Actualizar tabla
                </button>
                <SlIcon
                  className="hover:cursor-pointer"
                  name="info-circle"
                  onClick={() => handleShowDetailInfo(5)}
                ></SlIcon>
              </div>
              {descriptionBtn === 5 && (
                <p className="pt-1 text-textSecondary">{btnsDescriptions[5]}</p>
              )}
            </div>

            <hr className="text-cardBorder" />

            <div className="">
              <div className="flex items-center justify-between">
                <button
                  className="text-textPrimary font-bold flex items-center gap-2 hover:underline  border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
                  onClick={actualizarCupos}
                >
                  Actualizar Cupos
                </button>
                <SlIcon
                  className="hover:cursor-pointer"
                  name="info-circle"
                  onClick={() => handleShowDetailInfo(6)}
                ></SlIcon>
              </div>
              {descriptionBtn === 6 && (
                <p className="pt-1 text-textSecondary">{btnsDescriptions[6]}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EstadoGeneral;
